<template>
    <b-overlay :show="isLoading" rounded="sm">
        <div class="card card-custom card-stretch gutter-b">
            <div class="card-header border-0 pt-5">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label font-weight-bolder text-dark">Listado de cursos</span>
                    <span class="text-muted mt-3 font-weight-bold font-size-sm">
            {{ totalRows }} en total
          </span>
                </h3>
                <div class="card-toolbar">
                    <a
                            @click="goNewItem"
                            class="btn btn-primary font-weight-bolder font-size-sm mr-3"
                    >
                        Nuevo curso
                    </a>
                </div>
            </div>
            <div class="card-body pb-10" data-app>
                <v-text-field
                        v-model="dtSearch"
                        label="Buscar"
                        append-icon="search"
                        class="mx-4"
                        single-line
                        hide-details
                ></v-text-field>
                <v-data-table
                        :headers="headers"
                        :items="list"
                        :options.sync="options"
                        :server-items-length="totalRows"
                        :loading="isLoadingTable"
                        :footer-props="{
                            itemsPerPageOptions: [5, 10, 25, 50],
                            showFirstLastPage: true,
                            itemsPerPageText: 'Resultados por página'
                        }"
                        :items-per-page="itemsPerPage"
                        class="elevation-1"
                        locale="es"
                >
                    <template v-slot:item.image="{ item }">
                        <div class="">
                            <img
                                    :src="item.image"
                                    class="image-table medium align-self-center"
                                    alt=""
                            />
                        </div>
                    </template>
                    <template v-slot:item.name="{ item }">
                        <div class="">
                            {{ item.name }}
                        </div>
                    </template>
                    <template v-slot:item.description="{ item }">
                        <div class="container-max-250">
                            {{ item.description }}
                        </div>
                    </template>
                    <template v-slot:item.status="{ item }">
                        <div class="text-center">
                          <span
                                  v-if="item.status == '1'"
                                  class="label label-lg label-inline label-light-success"
                          >
                            Activo
                          </span>
                            <span
                                    v-if="item.status != '1'"
                                    class="label label-lg label-inline label-light-danger"
                            >
                            Inactivo
                          </span>
                        </div>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <div class="text-center">
                            <a @click="goDetail(item)" class="btn btn-icon btn-light btn-sm">
                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg
                                          src="media/svg/icons/Design/ZoomMinus.svg"
                                  ></inline-svg>
                                </span>
                            </a>
                            <a @click="editItem(item)" class="btn btn-icon btn-light btn-sm mx-3">
                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg
                                          src="media/svg/icons/Communication/Write.svg"
                                  ></inline-svg>
                                </span>
                            </a>
                            <a
                                    @click="deleteConfirmation(item.id)"
                                    v-b-modal.modal-delete
                                    class="btn btn-icon btn-light btn-sm"
                            >
                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg
                                          src="media/svg/icons/General/Trash.svg"
                                  ></inline-svg>
                                </span>
                            </a>
                        </div>
                    </template>
                    <template slot="no-data"> Ningún dato para mostrar</template>
                </v-data-table>
            </div>
        </div>
        <b-modal id="modal-delete" title="¿Estas seguro?">
            Los datos serán borrados de forma permanente
            <template #modal-footer="{ ok, cancel, hide }">
                <b-button size="sm" variant="primary" @click="cancel()">
                    Cancelar
                </b-button>
                <b-button size="sm" variant="danger" @click="deleteItem(), ok()">
                    Aceptar
                </b-button>
            </template>
        </b-modal>
    </b-overlay>
</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import {COURSES_SET_SELECTED_COURSE,
        COURSES_GET_COURSES_LIST,
        COURSES_DELETE_COURSE} from "@/store/modules/courses.module";

    export default {
        name: "CoursesList",
        data() {
            return {
                currentPage: 1,
                itemsPerPage: 10,
                isLoading: false,
                isLoadingTable: false,
                options: {},
                idToDelete: 0,
                headers: [
                    {text: "Imagen", value: "image", align: "start", sortable: false, width: 100},
                    {text: "Nombre", value: "name", align: "start", sortable: false},
                    {text: "Descripción", value: "description", sortable: false},
                    {text: "Estado", value: "status", align: "center", sortable: false},
                    {text: "Acciones", value: "actions", align: "center", sortable: false},
                ],
                dtSearch: ""
            };
        },
        mounted() {
            this.$store.commit(COURSES_SET_SELECTED_COURSE, null)
            // this.getCoursesList()
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Cursos", route: "#"},
                {title: "Listado de cursos"},
            ]);
        },
        methods: {
            goNewItem() {
                this.$router.push("courses-form");
            },
            goDetail(item) {
                this.$store.commit(COURSES_SET_SELECTED_COURSE, item);
                this.$router.push("courses-detail");
            },
            deleteConfirmation(id) {
                this.idToDelete = id;
            },
            deleteItem() {
                this.$store.dispatch(COURSES_DELETE_COURSE, {
                    id: this.idToDelete
                }).then(() => {
                    window.location.reload()
                })
            },
            editItem(item) {
                this.$store.commit(COURSES_SET_SELECTED_COURSE, item);
                this.$router.push("courses-form");
            },
            getCoursesList(dataPagination){
                let scope = this
                scope.isLoading = true
                this.$store.dispatch(COURSES_GET_COURSES_LIST, {
                    page: dataPagination.page,
                    itemsPerPage: dataPagination.itemsPerPage,
                    search: this.dtSearch
                }).then(() => {
                    scope.isLoading = false
                })
            }
        },
        computed: {
            totalRows() {
                return this.$store.state.courses.coursesListTotal;
            },
            list() {
                return this.$store.state.courses.coursesList;
            },
        },
        watch: {
            options: {
                handler (dataPagination) {
                    this.getCoursesList(dataPagination)
                },
                deep: true,
            },
            dtSearch(){
                this.getCoursesList({
                    page: 1,
                    itemsPerPage: this.itemsPerPage
                })
            },
        }
    };
</script>

<style>
    .v-data-footer__select .v-select{
        padding: 15px!important;
    }
</style>
